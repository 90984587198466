<template>
<!-- 户外运动排名 -->
    <div>
        <div class="goBack">
            <div @click="getBack('shaoxingone')">
                <img src="../../assets/images/return.png" alt="">
            </div>
            <span>户外运动排名</span>
        </div>
        <div class="outdoor">
            <div>
                <outdoorsport></outdoorsport>
            </div>
            <div>
                <outdoorsrunning></outdoorsrunning>
            </div>
            <div>
                <outdoorsfoot></outdoorsfoot>
            </div>
            <div>
                <outdoorscycling></outdoorscycling>
            </div>
        </div>
    </div>
    
</template>

<script>
import {show} from '../../assets/js/animate'
export default {
    components: {
        outdoorsport: () => import('../charts/outdoorpain/outdoorsport.vue'),
        outdoorsrunning: () => import('../charts/outdoorpain/outdoorsrunning.vue'),
        outdoorsfoot: () => import('../charts/outdoorpain/outdoorsfoot.vue'),
        outdoorscycling: () => import('../charts/outdoorpain/outdoorscycling.vue'),
    },
    methods: {
        getBack(showdata){
            let _this = this;
            show(showdata, _this);
			$('#amap').show();
        }
    }
}
</script>

<style scoped>
.outdoor1{
    position: absolute;
    top:1.36rem;
    left:26.6%;
}
.outdoor2{
    position: absolute;
    top:1.36rem;
    left:50.7%;
}
.outdoor{
    width:18.2rem;
    position: absolute;
    top: 1.36rem;
    left:2.4%;
    display: flex;
    justify-content: space-between;
}
</style>